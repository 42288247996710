import React from 'react';
import { Link } from 'react-router-dom';

import { title } from '../config';

const Welcome: React.FC = () => {
  return (
    <section>
      <h2>Welcome</h2>
      <div className="subsection left">
        <p>Welcome to {title}!</p>
        <p>
          This website allows you to copy files over the network.
        </p>
        <p>
          You can start a file transfer in a few easy steps:
          <ol>
            <li>Open this website on one device</li>
            <li>
              Scan the QR code or open the URL from the "Invite" section on
              another device.
            </li>
            <li>
              Drag and drop your files onto a tile (any tile that isn't marked
              as "You", you can also click on a tile to open a file selection
              dialog).
            </li>
            <li>Accept the transfer on another device.</li>
          </ol>
        </p>
        <p>
          By interacting with the service you accept our{' '}
          <Link to="/privacy">Privacy Policy</Link> and{' '}
          <Link to="/tos">Terms of Service</Link>.
        </p>
        <p>
          Clicking the "Continue" button below this notice will save data
          into your browser's local storage. This data will be
          used to avoid showing you this notice in future.
        </p>
      </div>
    </section>
  );
};

export default Welcome;
