import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';

import { StateType } from '../reducers';
import QrCodeSection from '../components/QrCodeSection';
import TransfersSection from '../components/TransfersSection';
import IncompatibleBrowser from '../components/IncompatibleBrowser';
import ClipboardModal from '../modals/ClipboardModal';
import WelcomeModal from '../modals/WelcomeModal';
import { setClientNameAction, setNetworkNameAction } from '../actions/state';
import { allowQrCodeIntegration } from '../config';

const Transfers: React.FC = () => {
  const dispatch = useDispatch();
  const welcomed = useSelector((state: StateType) => state.welcomed);
  const [clipboardFiles, setClipboardFiles] = useState<File[]>([]);
  let { networkName, clientName } = useParams<{
    networkName: string;
    clientName: string;
  }>();
  const [href, setHref] = useState('');
  const [incompatibleBrowser, setIncompatibleBrowser] = useState(false);

  if (
    typeof clientName === 'undefined' ||
    clientName === null ||
    clientName === ''
  ) {
    clientName = 'Guest';
  }

  useEffect(() => {
    setHref(
      window.location.origin + window.location.pathname + window.location.hash
    );
    dispatch(setNetworkNameAction(networkName));
    dispatch(setClientNameAction(clientName));
  }, [setHref, networkName, clientName, dispatch]);

  useEffect(() => {
    setIncompatibleBrowser(
      !(
        'RTCPeerConnection' in window &&
        'WebSocket' in window &&
        'FileReader' in window
      )
    );
  }, []);

  useEffect(() => {
    const onPaste = (e: ClipboardEvent) => {
      const element = e.target as HTMLElement;
      if (element.tagName === 'TEXTAREA') {
        return;
      }

      const files = [];
      for (let item of e.clipboardData.items) {
        const file = item.getAsFile();

        if (file) {
          files.push(file);
        } else if (item.type === 'text/plain') {
          item.getAsString(str => {
            setClipboardFiles(files => [
              ...files,
              new File([str], 'clipboard.txt', { type: 'text/plain' }),
            ]);
          });
        }
      }

      setClipboardFiles(files);
    };

    document.addEventListener('paste', onPaste);

    return () => {
      document.removeEventListener('paste', onPaste);
    };
  });

  const dismissClipboard = useCallback(() => {
    setClipboardFiles([]);
  }, [setClipboardFiles]);

  return (
    <>
      {incompatibleBrowser ? <IncompatibleBrowser /> : null}
      <AnimatePresence>{!welcomed ? <WelcomeModal /> : null}</AnimatePresence>
      <AnimatePresence>
        {clipboardFiles.length > 0 ? (
          <ClipboardModal
            files={clipboardFiles}
            dismissClipboard={dismissClipboard}
          />
        ) : null}
      </AnimatePresence>
      {allowQrCodeIntegration ? (
        <section className="desktop-2col">
          <TransfersSection />
          <QrCodeSection href={href} />
        </section>
      ) : (
        <section className="desktop-1col">
          <TransfersSection />
        </section>
      )}
    </>
  );
};

export default Transfers;
